.pricings-event {
    .pricingevent {
        margin-top: 25px;
        .fc-event-title {
            color: #000000;
        }
    }
    .pricingeventLow {
        background-color: #007bff;
        opacity: 1;
    }
    .pricingeventHigh {
        background-color: #ff0000;
        opacity: 1;
    }
}