.login-page {
    .bg-login-blue {
        background: #152942;
    }
    .brand-logo {
        margin-bottom: 0px;
    }
    .welcome-msg {
        color: #ffffff;
    }
    .form-group {
        label {
            color: #fe9700;
        }
        input {
            background-color: #ffffff !important;
        }
    }
    .auth-link {
        color: #4c4c4c !important;
    }
    .createlink-btn {
        color: #fe9700 !important;
        &:hover {
            color: #4c4c4c !important;
        }
    }
}