.admin-logoplace {
    .navbar-brand img {
        height: 90px !important;
    }
}

.admin-nav-topbar {
    background-color: #152942 !important;
    .nav-item.nav-profile {
        .nav-profile-name {
            color: #ffffff !important;
            &:hover {
                color: #cccccc !important;
                ;
            }
        }
    }
}

.notification-on-card {
    a.btn {
        font-size: 1rem;
    }
}

.module-titlecontainer {
    .card-body {
        padding-top: 1rem;
        padding-bottom: 1rem;
        .module-title {
            margin-bottom: 0px !important;
            font-size: 2rem;
        }
        .breadcrumb {
            padding: 0.2rem 0rem;
            margin-bottom: 0px;
            background: none;
        }
    }
}

.list-container {
    .list-item {
        .rate-star {
            padding: 0rem 0rem 0.5rem 0rem;
            .mdi:before {
                font-size: 1.1rem;
                color: #fe9700;
            }
        }
        h4.service-title {
            font-size: 1.5rem;
            a {
                &:hover {
                    text-decoration: none;
                    color: #333333;
                }
            }
        }
        .review-msg {
            .mdi:before {
                font-size: 1.5rem;
                color: #fe9700;
            }
            a.btn-link {
                padding: 0rem;
                margin-top: -15px;
            }
        }
        .price-starting {
            margin-top: 10px;
            margin-bottom: 10px;
            .sub-title {
                font-size: 0.8rem;
                color: #333333;
            }
            .main-price {
                font-size: 1.5rem;
                color: #fe9700;
            }
            .sub-night {
                font-size: 0.8rem;
                color: #007bff;
            }
        }
        .btn-list-item {
            .btn-sm {
                padding: 10px !important;
                min-width: 4rem;
            }
        }
    }
}

.img-preview-input {
    .img-thumb {
        min-width: 100px;
        min-height: 100px;
        background-position: center;
        background-size: cover;
        text-align: right;
        .btn {
            padding: 5px;
            i {
                font-size: 1rem;
            }
        }
    }
}

.photo-gallery {
    .img-preview-input {
        .img-thumb {
            width: 100% !important;
        }
    }
}

.image-dropzone-container {
    width: 100%;
    height: 200px;
    border: dashed 1px #dddddd;
    margin-bottom: 20px;
}